import React from 'react';

import LogoLayout from '../global/components/layouts/logoLayout/logoLayout';


const IndexPage = () => {
  const path = '/';

  return (
    <LogoLayout>
      <h2>Welcome to Enneagram Official, the official home of the Enneagram.</h2>
      <br />

      <h2>What is the Enneagram?</h2>
      <p>The Enneagram is a tool used to identify one's personality based on 9 main defined types (referred to as "enneatypes")</p>
      <br />

      <h2>What is the Enneagram?</h2>
      <p>The Enneagram is a tool used to identify one's personality based on 9 main defined types (referred to as "enneatypes")</p>
      <br />

      <h2>What is the Enneagram?</h2>
      <p>The Enneagram is a tool used to identify one's personality based on 9 main defined types (referred to as "enneatypes")</p>
      <br />

      <h2>What is the Enneagram?</h2>
      <p>The Enneagram is a tool used to identify one's personality based on 9 main defined types (referred to as "enneatypes")</p>
      <br />

      <h2>What is the Enneagram?</h2>
      <p>The Enneagram is a tool used to identify one's personality based on 9 main defined types (referred to as "enneatypes")</p>
      <br />

      <h2>What is the Enneagram?</h2>
      <p>The Enneagram is a tool used to identify one's personality based on 9 main defined types (referred to as "enneatypes")</p>
      <br />

      <h2>What is the Enneagram?</h2>
      <p>The Enneagram is a tool used to identify one's personality based on 9 main defined types (referred to as "enneatypes")</p>
      <br />

      <h2>What is the Enneagram?</h2>
      <p>The Enneagram is a tool used to identify one's personality based on 9 main defined types (referred to as "enneatypes")</p>
      <br />

      <h2>What is the Enneagram?</h2>
      <p>The Enneagram is a tool used to identify one's personality based on 9 main defined types (referred to as "enneatypes")</p>
      <br />

      <h2>What is the Enneagram?</h2>
      <p>The Enneagram is a tool used to identify one's personality based on 9 main defined types (referred to as "enneatypes")</p>
      <br />

      <h2>What is the Enneagram?</h2>
      <p>The Enneagram is a tool used to identify one's personality based on 9 main defined types (referred to as "enneatypes")</p>
      <br />
    </LogoLayout>
  )
}

export default IndexPage
