import React from 'react';

import NormalizeStyles from '../../../styles/normalize.css';
import Classes from './generalLayoutForAllPages.module.css';

export default function GeneralLayoutForAllPages({ children }) {
  return (
    <body className={Classes.body}>
      {children}
    </body>
  )
}
