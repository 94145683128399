import React from 'react';

import GeneralLayoutForAllPages from '../generalLayoutForAllPages/generalLayoutForAllPages'
import LogoAnimationPiece1 from '../../../../assets/svgs/logo_animation_piece_1.svg';
import LogoAnimationPiece2 from '../../../../assets/svgs/logo_animation_piece_2.svg';
import LogoAnimationPiece3 from '../../../../assets/svgs/logo_animation_piece_3.svg';
import LogoAnimationPiece4 from '../../../../assets/svgs/logo_animation_piece_4.svg';
import LogoAnimationPiece5 from '../../../../assets/svgs/logo_animation_piece_5.svg';
import LogoAnimationPiece6 from '../../../../assets/svgs/logo_animation_piece_6.svg';
import MenuIcon from '../../../../assets/svgs/menu_icon.svg';
import Classes from './logoLayout.module.css';


export default function Layout({ children }) {
  addLogoAnimationOnScroll();

  return (
    <GeneralLayoutForAllPages>
      <div id="header" className={Classes.header} >
        <div id="headerContentWrap" className={Classes.headerContentWrap} >
          <div className={Classes.headerBorder}>
            <div className={Classes.headerContent}>
              <div className={Classes.animatedLogoWrap}>
                <div id="animatedLogoPiece1" className={Classes.animatedLogoPiece1}>
                  <LogoAnimationPiece1 />
                </div>
                <div id="animatedLogoPiece2" className={Classes.animatedLogoPiece2}>
                  <LogoAnimationPiece2 />
                </div>
                <div id="animatedLogoPiece3" className={Classes.animatedLogoPiece3}>
                  <LogoAnimationPiece3 />
                </div>
                <div id="animatedLogoPiece4" className={Classes.animatedLogoPiece4}>
                  <LogoAnimationPiece4 />
                </div>
                <div id="animatedLogoPiece5" className={Classes.animatedLogoPiece5}>
                  <LogoAnimationPiece5 />
                </div>
                <div id="animatedLogoPiece6" className={Classes.animatedLogoPiece6}>
                  <LogoAnimationPiece6 />
                </div>
              </div>
              <div className={Classes.menuIcon}>
                <MenuIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={Classes.contentWrap} id="contentWrap">
        <div className={Classes.content}>
          {children}
        </div>
      </div>
    </GeneralLayoutForAllPages>
  )
}

function addLogoAnimationOnScroll() {
  const Y_TRANSITION_HEIGHT = 30;
  let startScrollPosition = 0;
  if(typeof window !== 'undefined') {
    startScrollPosition = window.pageYOffset;
  }
  let mostRecentScrollDistance = startScrollPosition;

  function scroll() {
    let currentScrollPosition = 0;
    if(typeof window !== 'undefined') {
      currentScrollPosition = window.pageYOffset;
    }
    const scrollDistance = currentScrollPosition - startScrollPosition;
    const isDecreasingScroll = scrollDistance < mostRecentScrollDistance;

    animateLogo(currentScrollPosition, scrollDistance, isDecreasingScroll);
  }

  function animateLogo(currentScrollPosition, scrollDistance, isDecreasingScroll) {
    if(currentScrollPosition < 0) return;

    const LOGO_PIECE_2 = document.getElementById('animatedLogoPiece2');
    const LOGO_PIECE_3 = document.getElementById('animatedLogoPiece3');
    const LOGO_PIECE_4 = document.getElementById('animatedLogoPiece4');
    const LOGO_PIECE_5 = document.getElementById('animatedLogoPiece5');
    const LOGO_PIECE_6 = document.getElementById('animatedLogoPiece6');

    const HEADER_ELEMENT = document.getElementById('header');
    const HEADER_WRAP_ELEMENT = document.getElementById('headerContentWrap');

    mostRecentScrollDistance = scrollDistance;

    const opacity = 1 - ((1/Y_TRANSITION_HEIGHT)*scrollDistance);

    const element5StartPosition = 27;
    let element5Top = element5StartPosition - scrollDistance;
    if(element5Top < 0) element5Top = 0;

    let element6Top = 27 - scrollDistance;
    if(element6Top < -3) element6Top = -3;

    LOGO_PIECE_2.style.opacity = opacity;
    LOGO_PIECE_5.style.opacity = opacity;
    LOGO_PIECE_4.style.top = `${element5Top}px`;
    LOGO_PIECE_5.style.top = `${element5Top}px`;
    LOGO_PIECE_6.style.top = `${element6Top}px`;

    let headerHeight = 100 - scrollDistance;
    if(headerHeight < 70)  headerHeight = 70;
    if(headerHeight > 100) headerHeight = 100;

    if(scrollDistance > Y_TRANSITION_HEIGHT) {
      HEADER_ELEMENT.style.boxShadow = '0 1px 6px 0 rgba(32, 33, 36, 0.28)';
    } else {
      HEADER_ELEMENT.style.boxShadow = 'none';
    }

    HEADER_WRAP_ELEMENT.style.height = `${headerHeight}px`;

    if((scrollDistance > Y_TRANSITION_HEIGHT) || isDecreasingScroll) {
      const distancePastYTransition = scrollDistance - Y_TRANSITION_HEIGHT;
      const smallerXTransitionDistance = 46;
      const largerXTransitionDistance = 112;

      let element4Left = 64 - distancePastYTransition;
      if(element4Left < 18) element4Left = 18;
      if(element4Left > 64) element4Left = 64;

      let element3Left = 159 - (distancePastYTransition * (largerXTransitionDistance/smallerXTransitionDistance));
      if(element3Left < 47) element3Left = 47;
      if(element3Left > 159) element3Left = 159;

      let element6Left = 160 - (distancePastYTransition * (largerXTransitionDistance/smallerXTransitionDistance));
      if(element6Left < 48) element6Left = 48;
      if(element6Left > 160) element6Left = 160;

      LOGO_PIECE_4.style.left = `${element4Left}px`;
      LOGO_PIECE_3.style.left = `${element3Left}px`;
      LOGO_PIECE_6.style.left = `${element6Left}px`;
    }
  }

  if(typeof window !== 'undefined') {
    window.addEventListener('scroll', scroll);
  }
}
